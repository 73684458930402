@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Variables */
:root{
    --light-gray-colour: #eeeeee;
    --dark-gray-colour: #545454;
    --black-colour: #111111;

    --default-font-family: "Montserrat", sans-serif;
    --default-font-size: 16px;
    --default-line-height: 24px;

    --small-font-size: 12px;
    --medium-font-size: 16px;
    --large-font-size: 18px;
    --xlarge-font-size: 24px;

    --page_bgcolor: #fff;
    --page_bgimage: url('');
    --page_color: #111111;

    --heading_color: #236cc8;
    --heading_line: #236cc8;

    --link_color: #236cc8;
    --link_hover_color: #03a9f4;

    --icon_color: #236cc8;
    --del_icon_color: red;

    --list_grp_head_bgcolor: purple;
    --list_grp_head_color: white;
    --list_head_bgcolor: var(--dark-gray-colour);
    --list_head_color: var(--black-colour);
    --list_bgcolor: var(--light-gray-colour);
    --list_color: (--black-colour);

    --list_selected_bgcolor: white;
    --list_selected_color: #236cc8;

    --btn_default_color: white;
    --btn_default_bgcolor: #236cc8;
    --btn_default_hover_color: white;
    --btn_default_hover_bgcolor: #0a9ceb;

    --btn_cancel_color: white;
    --btn_cancel_bgcolor: #236cc8;
    --btn_cancel_hover_color: white;
    --btn_cancel_hover_bgcolor: #0a9ceb;
}
/* General Css */
body {
    width: 100%;
    height: 100vh;
    font-family: var(--default-font-family);
    font-size: var(--default-font-size);
    font-weight: 400;
    line-height: var(--default-line-height);
    color: var(--black-colour);
    background-color: var(--light-gray-border-colour);
}

a {
    color: var(--link_color);
}

a:hover {
    color: var(--link_hover_color);
}

.left-align{
    text-align: left;
}

.right-align{
    text-align: right;
}

.wrapper {
    width: 100vw;
    height: 100vh;
    background-color: var(--page_bgcolor);
    background-image: var(--page_bgimage);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 1em;
}

.container{
    width: 90%;
    max-width: 550px;
    height: 80%;
    text-align: center;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
}

/* Loading indicator */
.loader-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-top: 2rem;
}

.loader-message {
    margin: 0.25rem;
}

.loader {
    border: 0.2rem solid var(--light-gray-border-colour);
    border-top: 0.2rem solid var(--dark-gray-colour);
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Sections */
.section{
    padding: 1em;
}
.section p {
    text-align: left;
}
.section-heading{
    font-size: var(--large-font-size);
    color: var(--heading_color);
    text-align: left;
}
.section-heading::after{
    content: '';
    height: 2px;
    width: 100%;
    display: block;
    background-color: var(--heading_line);
    margin-bottom: 1em;
}

.section-row{
    width: 100%;
    display: flex;
    gap: 1em;
}

.section-row > div{
    text-align: left;
}

.section-footer{
    width: 100%;
    margin: 0;
    padding: 1.5em 0 0 0;
    
}

/* Card Details */
.card-detail-grid-row {
    width: 100%;
    display: grid;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 0.25fr;
    min-height: calc(var(--default-font-size) * 1.25);
    font-size: var(--default-font-size);
    border-bottom: 1px solid var(--light-gray-border-colour);
    margin-left: 0.25rem;
    margin-top: 0.25rem;
    color: var(--list_color);
    background-color: var(--list_bgcolor);
}

.card-detail-grid-row i,
.card-detail-grid-row svg {
    color: var(--icon_color);
    cursor: pointer;
    font-size: var(--large-font-size);
    margin-top: 0.1rem;
}

.card-detail-row-header {
    width: 100%;
    display: grid;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 0.25fr;
    background-color: var(--list_head_bgcolor);
    color: var(--list_head_color);
    min-height: calc(var(--default-font-size) * 1.5);
    padding: 0.25rem;
}

.card-grid-header {
    width: 100%;
    display: grid;
    grid-template-columns: 1.65fr 1fr 0.75fr;
    padding-left: 2.3rem;
    min-height: calc(var(--default-font-size) * 1.5);
    background-color: var(--list_head_bgcolor);
    color: var(--list_head_color);
}

.card-grid-row {
    width: 100%;
    display: grid;
    grid-template-columns: 0.15fr 1.5fr 1fr 0.5fr;
    min-height: calc(var(--default-font-size) * 1.25);
    font-size: var(--default-font-size);
    border-bottom: 1px solid var(--light-gray-border-colour);
    color: var(--list_color);
    background-color: var(--list_bgcolor);
    text-align: left;
}
.card-declined {
    color: var(--del_icon_color);
}

.card-declined-note {
    display: block;
    text-transform: uppercase;
    font-size: var(--small-font-size);
    margin-top: -0.5em;
}

.card-grid-footer {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0.5em 0 0 0;
}

.card-grid-footer span {
    color: var(--icon_color);
    font-size: var(--small-font-size);
    line-height: var(--small-font-size);
    font-weight: 700;
    margin-bottom: 0.5em;
}

/* Buttons */
.btn {
    padding: 10px 20px;
    margin-bottom: 20px;
    border: none;
    border-radius: var(--field-border-radius);
    cursor: pointer;
}
.btn-default {
    background-color: var(--btn_default_bgcolor);
    font-size: var(--default_font_size);
    color: var(--btn_default_color);
}

.btn-default:hover {
    background-color: var(--btn_default_hover_bgcolor);
    color: var(--btn_default_hover_color);
}

.btn-default:disabled {
    background-color: var(--btn_default_hover_bgcolor);
    pointer-events: none;
}

.btn-small {
    width: fit-content;
    padding: 5px 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: var(--field-border-radius);
    background-color: var(--btn_default_bgcolor);
    font-size: var(--default-font-size);
    color: var(--btn_default_color);
    cursor: pointer;
}

.btn-cancel {
    background-color: var(--btn_cancel_bgcolor);
    color: var(--btn_cancel_color);
    font-size: var(--default_font_size);
}

.btn-cancel:hover {
    background-color: var(--btn_cancel_hover_bgcolor);
    color: var(--btn_cancel_hover_color);
}

/* Dialog */
.dialog-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0, opacity 0.5s;
    z-index: 999;
}

.dialog-show {
    visibility: visible;
    opacity: 1;
}

.dialog-box {
    max-width: 98%;
    max-height: 98%;
    background-color: white;
    border: 1px solid var(--light-gray-border-colour);
    border-radius: var(--field-border-radius);
    z-index: 99;
}

.dialog-box .topmost {
    z-index: 99999;
}

.dialog-heading {
    font-size: var(--large-font-size);
    font-weight: 600;
    color: var(--page_header_color);
    line-height: var(--large-font-size);
}

.dialog-heading-message {
    font-size: var(--medium-font-size);
    line-height: var(--medium-font-size);
    margin: 1em auto;
}

.message-box-title,
.dialog-title {
    background-image: linear-gradient(to right,
            var(--page_header_bgcolor1),
            var(--page_header_bgcolor2));
    color: var(--page_header_color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    padding: 1.5rem;
    border-top-right-radius: var(--field-border-radius);
    border-top-left-radius: var(--field-border-radius);
}

.message-box-title h2,
.dialog-title h2 {
    font-size: var(--large-font-size);
    font-weight: 600;
    text-transform: uppercase;
}

.dialog-close {
    cursor: pointer;
    font-size: var(--large-font-size);
    padding-left: 1rem;
    padding-top: 0.25rem;
}

.dialog-body {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.dialog-body .input-button {
    text-align: right;
}

.dialog-footer {
    width: 100%;
    padding: 0 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
}

.dialog-footer .btn {
    margin: 10px 0;
}

/* Message Box */
.message-box-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1em;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.message-box {
    max-width: 95vw;
    min-height: 10em;
    background-color: white;
    border: 1px solid var(--light-gray-border-colour);
    border-radius: var(--field-border-radius);
}

.message-box-body {
    padding: 2rem;
}

.message-box-title {
    background-image: linear-gradient(to right,
            var(--page_header_bgcolor1),
            var(--page_header_bgcolor2));
    color: var(--page_header_color);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 1.5rem;
    border-top-right-radius: var(--field-border-radius);
    border-top-left-radius: var(--field-border-radius);
}

.message-box-title h2 {
    font-size: var(--large-font-size);
    font-weight: 600;
    text-transform: uppercase;
}

.input-error {
    display: block;
    font-size: var(--medium-font-size);
    color: var(--del_icon_color);
    line-height: var(--medium-font-size);
    margin-bottom: 10px;
}

.react-confirm-alert-overlay {
    z-index: 9999 !important;
}